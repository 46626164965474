import React from 'react'
import { graphql } from 'gatsby'
import VolunteeringHeader from '../components/VolunteeringHeader'
import VolunteeringAssociationsSection from '../components/VolunteeringAssociationsSection'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Seo from '../components/Seo'
import CookiePopUp from '../components/CookiePopUp'

export default function volunteeringTemplate({ data }) {

  const { wpPage: { title, translations, language, volunteering: { volunteeringIntro, volunteeringRepeater } },
    allWpMenu,
    allWp: { nodes: [optionsPage] } } = data

  const shareContent = volunteeringIntro.volunteeringDescription.replace(/<[^>]+>/g, '').slice(0, 160);



  return (
    <>
      <Seo title={title} description={shareContent} image={volunteeringIntro.volunteeringHeroImage && volunteeringIntro.volunteeringHeroImage.file.heroImage?.fluid.src} />
      <Header menuData={allWpMenu} language={language} translations={translations} />
      <section className="volunteering">
        <div className="volunteering__wrapper content-wrapper">
          <VolunteeringHeader volunteeringHeaderData={volunteeringIntro} />
          <VolunteeringAssociationsSection volunteeringAssociationsData={volunteeringRepeater} />
        </div>
      </section>
      <CookiePopUp language={language} />
      <Footer menuData={allWpMenu} language={language} footerOptionsPage={optionsPage} />
    </>
  )
}


export const volunteeringPageQuery = graphql`
  query volunteeringPage($id: String!) {
    wpPage(id: {eq: $id}) {
      translations {
        slug
        uri
      }
      language {
        locale
      }
      title
      volunteering {
        volunteeringIntro {
          volunteeringDescription
          volunteeringTitle
          volunteeringHeroImage {
            file: localFile {
              heroImage: childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
        volunteeringRepeater {
          sectionImage {
            file: localFile {
              sectionIcon: childImageSharp {
                fluid(maxWidth: 55) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
          sectionTitle
          sectionRepeater {
            subsectionTitle
            subsectionDescription
            subsectionName
            subsectionEmail
            subsectionPhone
          }
        }
      }
    }
    allWpMenu {
      ...getMenus
    }
    allWp {
      nodes {
        ...getOptionsPageFooterData
      }
    }
  }
`